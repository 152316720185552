import React from "react";
import styled, { css } from "styled-components";

// Services
import intl from "../../services/intl";
import { navigate } from "../../services/navigation";

// Utils
import { Color, rem, responsive } from "../../utils/style";
import {
  getBundleTotalFromPlans,
  getPlanIdAndQuantityArray,
} from "../../utils/bundle";
import { getPlanIdForProductSku } from "../../utils/planToProduct";

// Components
import { ModalTitle, ModalDescription } from "../bundle/index";
import ModalIngredientList from "./ModalIngredientList";
import DiscountButton from "../bundle/DiscountButton";
import MagicModal from "../MagicModal";
import Text from "../Text";

// Store
import { connect } from "react-redux";
import { addProductsToCart } from "../../store/cart/actions";

// Styled Elements
const contentStyle = css`
  padding: 24px 20px;

  ${responsive.sm`
    width: 700px;
    padding: 56px 40px;
  `};

  ${responsive.md`
    width: 774px;
    padding: 56px 80px;
  `};

  ${responsive.lg`
    width: 782px;
    padding: 56px 100px;
  `};
`;

const Divider = styled.div`
  background: ${Color.ritualBlue};
  height: 2px;
  margin-top: 32px;

  ${responsive.sm`
    margin-top: 24px;
    margin-bottom: 26px;
  `};
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${responsive.sm`
    width: calc(50% - (40px / 2));
    margin-right: 40px;

    &:last-child {
      margin-right: 0;
    }
  `};
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;

  ${responsive.sm`
    flex-direction: row;
  `};
`;

const BottomRow = styled(Row)`
  display: none;

  ${responsive.sm`
    display: flex;
    margin-top: 24px;
    justify-content: center;
  `}
`;

const MobileDiscountButton = styled(DiscountButton)`
  width: 100%;

  ${responsive.sm`
    display: none;
  `}
`;

const BundleIncludes = styled.p`
  color: rgba(20, 43, 111, 0.56);
  font-size: ${rem(12)};
  line-height: ${rem(20)};
  letter-spacing: 0.8px;
  text-transform: uppercase;
  margin: 61px 0 8px;

  ${responsive.sm`
    margin-top: 0;
  `};
`;

export class BundleModal extends React.Component {
  constructor() {
    super();

    this.handleStartNowClick = this.handleStartNowClick.bind(this);
  }

  handleStartNowClick() {
    const products = this.props.bundle.products;
    const planIds = products.map(product =>
      getPlanIdForProductSku(product.sku),
    );
    this.props.dispatchAddProductsToCart(getPlanIdAndQuantityArray(planIds));
    navigate("/cart");
  }

  render() {
    const { isOpen, onRequestClose, bundle, plans } = this.props;
    if (!bundle || !plans) return null;

    const { title, description, products } = bundle;
    if (!products) return null;

    const price = getBundleTotalFromPlans(bundle, plans);

    return (
      <MagicModal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentStyle={contentStyle}
        mobileFixedClose={true}
        mobileFullPage={true}
      >
        <ModalTitle>{title}</ModalTitle>
        <BundleIncludes>
          <Text
            id="bundle-landing.bundle-modal.includes"
            defaultMessage="Includes 2 bottles"
          />
        </BundleIncludes>
        <ModalDescription>{description}</ModalDescription>

        <MobileDiscountButton
          prefix={intl.t("general.button-add-cart", "Add to Cart")}
          onClick={this.handleStartNowClick}
          price={price}
        />
        <Divider />
        <Row>
          {products.map((product, i) => {
            return (
              <Column key={i}>
                <ModalIngredientList product={product} />
              </Column>
            );
          })}
        </Row>
        <BottomRow>
          <DiscountButton
            prefix={intl.t("general.button-add-cart", "Add to Cart")}
            onClick={this.handleStartNowClick}
            price={price}
          />
        </BottomRow>
      </MagicModal>
    );
  }
}

export default connect(null, {
  dispatchAddProductsToCart: addProductsToCart,
})(BundleModal);
