import React from "react";
import styled from "styled-components";

// Services
import intl from "../../services/intl";
import { navigate } from "../../services/navigation";

// Utils
import { Color, rem, responsive } from "../../utils/style";
import {
  getBundleTotalFromPlans,
  getPlanIdAndQuantityArray,
} from "../../utils/bundle";
import {
  getPlanIdForProductSku,
  getProductAttributes,
} from "../../utils/planToProduct";
import metrics from "../../utils/metrics";

// Components
import DiscountButton from "../bundle/DiscountButton";
import Img from "gatsby-image";

// Store
import { connect } from "react-redux";
import { addProductsToCart } from "../../store/cart/actions";

// Styled Elements
const Bundle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 100%;

  margin: 0 0 24px 0;
  border-radius: 16px;
  background: rgba(242, 241, 245, 0.56);
  padding: 24px 16px 32px;

  &:last-child {
    margin-bottom: 0;
  }

  ${responsive.sm`
    width: 280px;
    margin: 0 10px 24px;
  `};

  ${responsive.md`
    width: 294px;
    margin: 0 26px 0 0;
    padding: 32px 0 40px;

    &:last-child {
      margin-right: 0;
    }
  `};

  ${responsive.lg`
    width: 370px;
    margin: 0 30px 0 0;
    padding-left: 25px;
    padding-right: 25px;

    &:last-child {
      margin-right: 0;
    }
  `};
`;

const BundleTitle = styled.h3`
  font-size: ${rem(20)};
  line-height: ${rem(30)};
  font-weight: 500;
  margin-bottom: 6px;

  ${responsive.md`
    font-size: ${rem(26)};
    line-height: ${rem(36)};
    letter-spacing: -0.3px;
  `};
`;

const BundleProducts = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 16px 0;
  width: 100%;

  ${responsive.md`
    margin-bottom: 32px;
  `};
`;

const BundleIcon = styled.span`
  position: absolute;
  z-index: 10;
  top: calc(50% - 21px);
  left: calc(50% - 21px);
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: #fff;
  margin-top: -12px;

  &::after,
  &::before {
    content: "";
    width: 2px;
    height: 18px;
    background-color: ${Color.ritualBlue};

    position: absolute;
    left: calc(50% - 1px);
    top: calc(50% - 9px);
  }

  &::after {
    transform: rotate(90deg);
  }

  ${responsive.md`
    width: 56px;
    height: 56px;
    top: calc(50% - 28px);
    left: calc(50% - 28px);

    &::after,
    &::before {
      height: 20px;
      top: calc(50% - 10px);
    }
  `};
`;

const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 0 0 50%;

  p {
    margin: 0;
    font-size: ${rem(14)};
    line-height: ${rem(24)};
    font-weight: 500;
    opacity: 0.56;

    ${responsive.md`
      font-size: ${rem(16)};
      line-height: ${rem(26)};
    `};
  }
`;

const ProductImage = styled(Img)`
  width: 100%;
  height: auto;
`;

const BundleButton = styled(DiscountButton)`
  width: 100%;

  ${responsive.md`
    width: auto;
  `};
`;

const StrikedPrice = styled.span`
  text-decoration: line-through;
  margin-left: 7px;
  opacity: 0.56;
`;

const LearnMoreLink = styled.span`
  margin: 16px 0 0;
  font-size: ${rem(16)};
  line-height: ${rem(26)};
  font-weight: 500;
  border-bottom: 2px solid ${Color.ritualBlue};
  cursor: pointer;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.56;
  }
`;

export class ProductBundle extends React.Component {
  constructor(props) {
    super(props);

    const { bundle, plans } = this.props;
    const total = getBundleTotalFromPlans(bundle, plans);

    this.state = {
      firstDemographic: plans[bundle?.products[0]?.sku]?.demographic,
      secondDemographic: plans[bundle?.products[1]?.sku]?.demographic,
      total,
    };

    this.handleStartNowClick = this.handleStartNowClick.bind(this);
    this.handleLearnMoreClick = this.handleLearnMoreClick.bind(this);
  }

  renderProductImage(image, demographic) {
    return (
      <ImageWrapper>
        {image && (
          <ProductImage
            fluid={image.fluid}
            alt={image.description}
            loading="eager"
            style={{
              userSelect: "none",
              userDrag: "none",
              pointerEvents: "none",
              touchCallout: "none",
            }}
          />
        )}
        <p>{demographic}</p>
      </ImageWrapper>
    );
  }

  handleStartNowClick() {
    const { bundleIndex, bundle } = this.props;
    const { products } = bundle;
    const planIds = products.map(product =>
      getPlanIdForProductSku(product.sku),
    );

    const productAttributes = products.map(product => {
      const planId = getPlanIdForProductSku(product.sku);
      return getProductAttributes(planId);
    });

    metrics.track("Featured Product Bundle Added", {
      name: bundle.title,
      products: productAttributes,
      position: bundleIndex + 1,
    });

    this.props.dispatchAddProductsToCart(getPlanIdAndQuantityArray(planIds));
    navigate("/cart");
  }

  handleLearnMoreClick() {
    const { bundleIndex, openModal, bundle } = this.props;
    openModal(bundleIndex, bundle);
  }

  render() {
    const { firstDemographic, secondDemographic, total } = this.state;
    const { bundle } = this.props;
    const { title, firstProductImage, secondProductImage } = bundle;

    return (
      <Bundle>
        <BundleTitle>{title}</BundleTitle>
        <BundleProducts>
          {this.renderProductImage(firstProductImage, firstDemographic)}
          <BundleIcon />
          {this.renderProductImage(secondProductImage, secondDemographic)}
        </BundleProducts>
        <BundleButton
          onClick={this.handleStartNowClick}
          className="yellow-cta"
          prefix={intl.t("general.button-add-cart", "Add to Cart")}
          price={total}
        />
        <LearnMoreLink onClick={this.handleLearnMoreClick} role="button">
          {intl.t("general.learn-more", "Learn More")}
        </LearnMoreLink>
      </Bundle>
    );
  }
}

export default connect(null, {
  dispatchAddProductsToCart: addProductsToCart,
})(ProductBundle);
