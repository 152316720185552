import React from "react";
import styled from "styled-components";
import { graphql, StaticQuery } from "gatsby";

// Services
import intl from "../../services/intl";

// Utils
import { Color, rem, responsive } from "../../utils/style";
import { Icons } from "../../utils/svg";
import metrics from "../../utils/metrics";

// Components
import RitualButton from "../global/RitualButton";
import BundleRibbon from "../bundle/Ribbon";
import Img from "gatsby-image";
import Text from "../Text";

// graphql query
const componentQuery = graphql`
  query CustomBundleQuery {
    bottleImage: contentfulAsset(
      contentful_id: { eq: "77kYdlYrh6chRwfLgqImWB" }
    ) {
      title
      fluid(maxWidth: 912, quality: 90) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
      description
    }
  }
`;

// Styled Elements
const Bundle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  width: 100%;

  margin: 0 0 24px 0;
  border-radius: 16px;
  background: rgba(242, 241, 245, 0.56);
  padding: 24px 16px 32px;

  &:last-child {
    margin-bottom: 0;
  }

  ${responsive.sm`
    width: 280px;
    margin: 0 10px 24px;
    padding-bottom: 74px;
  `};

  ${responsive.md`
    width: 294px;
    margin: 0 26px 0 0;
    padding: 32px 0 40px;

    &:last-child {
      margin-right: 0;
    }
  `};

  ${responsive.lg`
    width: 370px;
    margin: 0 30px 0 0;
    padding-left: 25px;
    padding-right: 25px;

    &:last-child {
      margin-right: 0;
    }
  `};
`;

const BundleTitle = styled.h3`
  font-size: ${rem(20)};
  line-height: ${rem(30)};
  font-weight: 500;
  margin-bottom: 6px;

  ${responsive.md`
    font-size: ${rem(26)};
    line-height: ${rem(36)};
    letter-spacing: -0.3px;
  `};
`;

const BundleProducts = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 16px 0;
  width: 100%;

  ${responsive.md`
    margin-bottom: 32px;
  `};
`;

const BundleIcon = styled.span`
  position: absolute;
  z-index: 10;
  top: calc(50% - 21px);
  left: calc(50% - 21px);
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: #fff;
  margin-top: -12px;

  &::after,
  &::before {
    content: "";
    width: 2px;
    height: 18px;
    background-color: ${Color.ritualBlue};

    position: absolute;
    left: calc(50% - 1px);
    top: calc(50% - 9px);
  }

  &::after {
    transform: rotate(90deg);
  }

  ${responsive.md`
    width: 56px;
    height: 56px;
    top: calc(50% - 28px);
    left: calc(50% - 28px);

    &::after,
    &::before {
      height: 20px;
      top: calc(50% - 10px);
    }
  `};
`;

const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 0 0 50%;

  p {
    margin: 0;
    font-size: ${rem(14)};
    line-height: ${rem(24)};
    font-weight: 500;
    opacity: 0.56;

    ${responsive.md`
      font-size: ${rem(16)};
      line-height: ${rem(26)};
    `};
  }
`;

const ProductImage = styled(Img)`
  width: 100%;
  height: auto;
`;

const BundleButton = styled(RitualButton)`
  width: 100%;

  svg {
    width: 18px;
    height: 12px;
    margin-left: 16px;
    transition: all 0.2s;
  }

  ${responsive.md`
    width: auto;
  `};

  &:hover {
    svg {
      g {
        stroke: ${Color.white};
      }
    }
  }
`;

const DiscountBanner = styled(BundleRibbon)`
  margin: 18px 0 0 0;
  background-color: #ffed8d;

  &::before {
    border-color: #ffed8d;
    border-left-color: transparent;
  }

  &::after {
    border-color: #ffed8d;
    border-right-color: transparent;
  }

  font-size: ${rem(12)};
  line-height: ${rem(20)};
`;

export const CustomBundle = class CustomBundle extends React.Component {
  handleSegmentEvent() {
    metrics.track("CTA Clicked", {
      location: "featured bundle section",
      title: "build a bundle",
      nonInteraction: false,
    });
  }

  renderProductImage(image, title) {
    return (
      <ImageWrapper>
        {image && (
          <ProductImage
            fluid={image.fluid}
            alt={image.description}
            loading="eager"
            style={{
              userSelect: "none",
              userDrag: "none",
              pointerEvents: "none",
              touchCallout: "none",
            }}
          />
        )}
        <p>{title}</p>
      </ImageWrapper>
    );
  }

  render() {
    const bottleImage = this.props?.data?.bottleImage;

    return (
      <Bundle>
        <BundleTitle>
          <Text
            id="bundle-landing.custom.title"
            defaultMessage="The Home Made"
          />
        </BundleTitle>
        <BundleProducts>
          {bottleImage &&
            this.renderProductImage(
              bottleImage,
              intl.t("general.yours", "Yours"),
            )}
          <BundleIcon />
          {bottleImage &&
            this.renderProductImage(
              bottleImage,
              intl.t("general.theirs", "Theirs"),
            )}
        </BundleProducts>
        <BundleButton
          onClick={this.handleSegmentEvent.bind(this)}
          to="/build-a-bundle"
          className="yellow-cta"
        >
          <Text
            id="bundle-landing.custom.build"
            defaultMessage="Build Your Bundle"
          />{" "}
          <Icons.ArrowRoundedRight />
        </BundleButton>
        <DiscountBanner twoSided={true} />
      </Bundle>
    );
  }
};

export default props => (
  <StaticQuery
    query={componentQuery}
    render={data => <CustomBundle {...props} data={data} />}
  />
);
