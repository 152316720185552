import React from "react";
import styled from "styled-components";
import { graphql, StaticQuery } from "gatsby";

// Services
import intl from "../../services/intl";

// Utils
import { rem, responsive } from "../../utils/style";

// Components
import LargeContainer from "../global/LargeContainer";
import Img from "gatsby-image";

// graphql query
const componentQuery = graphql`
  query UGCBannerQuery {
    ugcImages: allContentfulAsset(
      filter: { contentful_id: { eq: "1zmcKgHPtggFFLESVZc7Q6" } }
    ) {
      nodes {
        node_locale
        title
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        description
      }
    }
  }
`;

// Styled Elements
const Container = styled(LargeContainer)`
  background-color: rgba(242, 241, 245, 0.56);
  margin-bottom: 56px;

  ${responsive.md`
    margin-bottom: 120px;
  `};
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;

  ${responsive.sm`
    flex-direction: row;
    justify-content: center;
    padding: 80px 0;
  `};
`;

const ContentColumn = styled.div`
  order: 1;
  padding: 0 16px 48px;
  width: 100%;

  ${responsive.sm`
    width: 300px;
    margin-right: 60px;
    order: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
  `};

  ${responsive.md`
    width: 400px;
    margin-right: 80px;
  `};

  ${responsive.lg`
    width: 470px;
    margin-right: 130px;
  `};
`;

const ImageColumn = styled.div`
  order: 0;
  padding: 48px 16px 40px;

  ${responsive.sm`
    order: 1;
    padding: 0;
    width: 260px;
    height: 260px;
  `};

  ${responsive.md`
    width: 346px;
    height: 346px;
  `};

  ${responsive.lg`
    width: 430px;
    height: 430px;
  `};
`;

const Title = styled.h3`
  font-size: ${rem(26)};
  line-height: ${rem(36)};
  letter-spacing: -0.3px;
  font-weight: 500;
  margin-bottom: 24px;
  text-align: center !important;

  ${responsive.sm`
    text-align: left !important;
  `};

  ${responsive.md`
    font-size: ${rem(40)};
    line-height: ${rem(50)};
    letter-spacing: -1px;
    margin-bottom: 40px;
  `};
`;

const Content = styled.p`
  font-size: ${rem(18)};
  line-height: ${rem(28)};
  font-weight: 500;
  margin-bottom: 0;
  text-align: center !important;

  ${responsive.sm`
    text-align: left !important;
  `};

  ${responsive.md`
    font-size: ${rem(26)};
    line-height: ${rem(36)};
    letter-spacing: -0.3px;
  `};
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const UGCBannerComponent = class UGCBanner extends React.Component {
  render() {
    const title = intl.t(
      "bundle-landing.ugc-banner.title",
      `A healthy home is what you make it`,
    );

    const content = intl.t(
      "bundle-landing.ugc-banner.content",
      `We’re here to help make it easier for everyone. Create a bundle that’s right for your home, and help turn healthy habits into a shared Ritual.*`,
    );

    const image = intl.cf(this.props?.data?.ugcImages.nodes, true);

    return (
      <Container>
        <Row>
          <ContentColumn>
            <Title className="d-none d-sm-block">{title}</Title>
            <Content>{content}</Content>
          </ContentColumn>
          <ImageColumn>
            <Title className="d-block d-sm-none">{title}</Title>
            <ImageWrapper>
              {image && (
                <Img
                  fluid={image.fluid}
                  alt={image.description}
                  style={{
                    userSelect: "none",
                    userDrag: "none",
                    pointerEvents: "none",
                    touchCallout: "none",
                    width: "100%",
                    height: "100%",
                  }}
                />
              )}
            </ImageWrapper>
          </ImageColumn>
        </Row>
      </Container>
    );
  }
};

export default props => (
  <StaticQuery
    query={componentQuery}
    render={data => <UGCBannerComponent {...props} data={data} />}
  />
);
