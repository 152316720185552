import React from "react";
import { graphql } from "gatsby";

// Components
import PageSEO from "../components/seo/Page";
import Hero from "../components/bundleLanding/Hero";
import PopularBundles from "../components/bundleLanding/PopularBundles";
import ValueProps from "../components/product/ValueProps";
import BundleBenefits from "../components/bundleLanding/BundleBenefits";
import UGCBanner from "../components/bundleLanding/UGCBanner";

import { variation } from "../utils/launchDarkly";

// Redux
import { connect } from "react-redux";
import bannersSelectors from "../store/banners/selectors";
import subscriptionSelectors from "../store/subscription/selectors";

export class BundleLandingPage extends React.Component {
  constructor(props) {
    super(props);

    const {
      slug,
      pageTitle,
      pageDescription,
      socialImage,
    } = props.data.contentfulBundleLandingPage;

    this.state = {
      seo: {
        pagePath: `${slug}`,
        title: pageTitle,
        description: pageDescription,
        image: {
          url: socialImage.file.url,
          width: socialImage.file.details.image.width,
          height: socialImage.file.details.image.height,
        },
      },
    };
  }

  componentDidMount() {
    this.props.updatePageData({
      label: "Shop Bundles",
    });

    // hide the bundle banner if its the clickable variant
    const isBundleBanner = this.props.bannerType === "BUNDLE";
    const bannerVariation = variation("bundle-banner");
    const showBanner =
      isBundleBanner && bannerVariation === "with-cta" ? false : true;

    this.props.updateLayoutState({
      navColor: "#FFFFFF",
      banner: showBanner,
    });
  }

  render() {
    const {
      data,
      hasActiveSubscription,
      hasBundlePromoEligibleSubscription,
      pageContext,
    } = this.props;
    const {
      title,
      subheader,
      discount,
      heroImageDesktop,
      heroImageMobile,
      popularBundlesHeadline,
      valueProps,
      bundleBenefitsImage,
      bundleBenefits,
      productBundles,
    } = data.contentfulBundleLandingPage;

    const { plans } = pageContext;

    const heroProps = {
      title,
      subheader,
      heroImageDesktop,
      heroImageMobile,
      discount,
      hasActiveSubscription,
      hasBundlePromoEligibleSubscription,
    };

    const popularBundleProps = {
      title: popularBundlesHeadline,
      productBundles,
      plans,
      discount,
    };

    return (
      <>
        <PageSEO {...this.state.seo} noIndex={true} />

        <Hero {...heroProps} />

        <PopularBundles {...popularBundleProps} />

        <div className="mt-6 mb-6 mt-md-7 mb-md-7">
          <ValueProps
            margin20={true}
            valueProps={valueProps.labels}
            icons={valueProps.icons}
            removePadding={true}
          />
        </div>

        <UGCBanner />

        <BundleBenefits
          className="mb-sm-7 mb-md-9"
          image={bundleBenefitsImage}
          benefits={bundleBenefits}
          hasActiveSubscription={hasActiveSubscription}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    bannerType: bannersSelectors.bannerType(state),
    hasActiveSubscription: subscriptionSelectors.hasActiveSubscription(state),
    hasBundlePromoEligibleSubscription: subscriptionSelectors.hasBundlePromoEligibleSubscription(
      state,
    ),
  };
};

export default connect(mapStateToProps)(BundleLandingPage);

export const pageQuery = graphql`
  query BundleLandingPage($locale: String!, $slug: String!) {
    contentfulBundleLandingPage(
      node_locale: { eq: $locale }
      slug: { eq: $slug }
    ) {
      title
      slug
      subheader
      discount
      heroImageDesktop {
        title
        fluid(maxWidth: 1920, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        description
      }
      heroImageMobile {
        title
        fluid(maxWidth: 757, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        description
      }
      popularBundlesHeadline
      valueProps {
        labels
        icons
      }
      bundleBenefitsImage {
        title
        fluid(maxWidth: 555, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        description
      }
      bundleBenefits {
        title
        items {
          body
          icon
          title
        }
      }
      productBundles {
        title
        slug
        description
        firstProductImage {
          title
          fluid(maxWidth: 912, quality: 90) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          description
        }
        secondProductImage {
          title
          fluid(maxWidth: 912, quality: 90) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          description
        }
        products {
          sku
          name {
            name
            childMarkdownRemark {
              html
            }
          }
          headerDescription
          heroFeaturedImages {
            title
            fluid(maxWidth: 555, quality: 90) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            description
          }
          supplementFacts {
            simpleIngredients {
              name
              dosage
            }
          }
        }
      }
      pageTitle
      pageDescription
      socialImage {
        file {
          url
          details {
            image {
              width
              height
            }
          }
        }
      }
    }
  }
`;
