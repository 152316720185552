import React from "react";
import styled, { css } from "styled-components";

// Utils
import { Font, Color, rem, responsive } from "../../utils/style";
import {
  getPlanIdForProductSku,
  getProductAttributes,
} from "../../utils/planToProduct";
import metrics from "../../utils/metrics";

// Components
import Container from "../Container";
import Row from "../Row";
import ProductBundle from "./ProductBundle";
import CustomBundle from "./CustomBundle";
import BundleModal from "./BundleModal";

// Styled Elements
const Wrapper = styled.div`
  margin-top: 56px;

  ${responsive.md`
    margin-top: 80px;
  `}
`;

const PopularBundlesTitle = styled.h2`
  text-align: center !important;
  ${Font.circular};
  color: ${Color.ritualBlue};
  font-size: ${rem(26)};
  line-height: ${rem(36)};
  letter-spacing: -0.3px;
  font-weight: 500;
  margin: 0 auto 32px;

  ${responsive.md`
    font-size: ${rem(40)};
    line-height: ${rem(50)};
    letter-spacing: -1px;
    margin-bottom: 56px;
  `};
`;

const BundlesWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  ${responsive.sm`
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-items: stretch;
  `};

  ${responsive.md`
    flex-wrap: nowrap;
  `};
`;

export default class PopularBundles extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
      selectedBundle: 0,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal(bundleIndex, bundle) {
    this.setState({
      isModalOpen: true,
      selectedBundle: bundleIndex,
    });

    const productAttributes = bundle.products.map(product => {
      const planId = getPlanIdForProductSku(product.sku);
      return getProductAttributes(planId);
    });

    metrics.track("Overlay Opened", {
      title: "Learn More",
      location: "Featured Product Bundle",
      name: bundle.title,
      products: productAttributes,
    });
  }

  closeModal() {
    this.setState({
      isModalOpen: false,
    });
  }

  render() {
    const { isModalOpen, selectedBundle } = this.state;
    const {
      discount,
      title,
      productBundles,
      plans,
      className = "",
    } = this.props;

    const customBundle = {
      title: "The Home Made",
      firstProductImage: undefined,
      secondProductImage: undefined,
      products: undefined,
    };

    return (
      <>
        <Wrapper className={className}>
          <Container>
            <Row>
              <div className="col-12 p-sm-0">
                <PopularBundlesTitle>{title}</PopularBundlesTitle>

                <BundlesWrapper>
                  {productBundles &&
                    productBundles
                      .slice(0, 2)
                      .map((bundle, i) => (
                        <ProductBundle
                          key={i}
                          discount={discount}
                          bundle={bundle}
                          bundleIndex={i}
                          plans={plans}
                          openModal={this.openModal}
                        />
                      ))}
                  <CustomBundle />
                </BundlesWrapper>
              </div>
            </Row>
          </Container>
        </Wrapper>
        {productBundles && (
          <BundleModal
            isOpen={isModalOpen}
            onRequestClose={this.closeModal}
            bundle={productBundles[selectedBundle]}
            plans={plans}
            discount={discount}
          />
        )}
      </>
    );
  }
}
