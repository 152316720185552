import React from "react";
import styled, { css } from "styled-components";

// Services
import intl from "../../services/intl";

// Utils
import { Font, Color, rem, responsive } from "../../utils/style";
import { Icons } from "../../utils/svg";
import metrics from "../../utils/metrics";

// Components
import Container from "../Container";
import Row from "../Row";
import PageHero from "../PageHero";
import RitualButton from "../global/RitualButton";
import BundleRibbon from "../bundle/Ribbon";

// Styled Elements
const Wrapper = styled.div``;

const HeroContent = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  display: none;

  ${responsive.sm`
    display: flex;
  `};

  h1 {
    font-size: ${rem(30)};
    line-height: ${rem(36)};
    font-weight: 500;
    letter-spacing: -0.4px;
    color: ${Color.ritualBlue};
    margin: 0 0 16px;

    ${responsive.md`
      font-size: ${rem(48)};
      line-height: ${rem(54)};
      letter-spacing: -1.4px;
      margin-bottom: 24px;
    `};

    ${responsive.lg`
      font-size: ${rem(66)};
      line-height: ${rem(72)};
      letter-spacing: -2px;
    `};
  }

  p {
    ${Font.dutch};
    font-size: ${rem(16)};
    line-height: ${rem(26)};
    font-weight: normal;
    color: ${Color.ritualBlue};
    margin: 0;

    ${responsive.md`
      font-size: ${rem(18)};
      line-height: ${rem(28)};
    `};
  }
`;

const MobileColumn = styled.div`
  padding-left: 20px;
  padding-right: 20px;

  h1 {
    font-size: ${rem(30)};
    line-height: ${rem(36)};
    font-weight: 500;
    letter-spacing: -0.4px;
    color: ${Color.ritualBlue};
    margin: 0 0 16px;
  }

  p {
    ${Font.dutch};
    font-size: ${rem(16)};
    line-height: ${rem(26)};
    font-weight: normal;
    color: ${Color.ritualBlue};
    margin: 0 0 24px;
  }
`;

const BarrierBustersList = styled.ul`
  list-style: none;
  margin: 24px 0 0;
  padding: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  li {
    margin-right: 20px;
    font-size: ${rem(14)};
    line-height: ${rem(20)};
    font-weight: 300;
    color: ${Color.ritualBlue};
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 12px;
      height: 9px;
      margin-right: 8px;
    }

    &:last-child {
      margin: 0;
    }
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 24px;

  ${responsive.sm`
    display: flex;
    flex-direction: column;
  `};

  ${responsive.md`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
    flex-direction: row;
  `};

  &.activeSubscription {
    a {
      width: 165px !important;
    }
  }
`;

const BundleButton = styled(RitualButton)`
  margin-right: 24px;
  margin-bottom: 8px;
  width: 100%;

  &:last-of-type {
    margin-right: 0;
    margin-bottom: 0;
  }

  ${responsive.md`
    margin-bottom: 0;
    width: auto;
  `};

  svg {
    width: 19px;
    height: 12px;
    margin-left: 16px;

    g {
      transition: stroke 0.2s;
      stroke: white;
    }
  }

  &:hover {
    svg {
      g {
        stroke: ${Color.ritualBlue};
      }
    }
  }
`;

const DiscountBanner = styled.div`
  width: fit-content;
  margin-top: 24px;

  ${responsive.md`
    margin-top: 0;
    margin-left: 24px;
  `}
`;

const HeroSizedBanner = styled.div`
  position: relative;
  margin: 0 auto;
  width: calc(100vw - 16px);

  ${responsive.sm`
    width: calc(100vw - 48px);
  `}

  ${responsive.lg`
    width: calc(100vw - 80px);
    max-width: 1920px;
  `}
`;

const BarrierBustersBanner = styled(HeroSizedBanner)`
  background: rgba(242, 241, 245, 0.56);
  color: ${Color.ritualBlue};
  padding: 17px 0;
  text-align: center;

  padding-left: 8px;
  padding-right: 8px;

  align-items: center;
  justify-content: center;

  display: none;

  ${responsive.sm`
    display: flex;
    padding-left: 0px;
    padding-right: 0px;
  `}

  ${responsive.md`
    padding: 20px 0;
  `};

  p {
    ${Font.circular};
    font-size: ${rem(14)};
    line-height: ${rem(20)};
    font-weight: 300;
    margin: 0 48px 0 0;

    display: flex;
    align-items: center;
    justify-content: center;

    &:last-child {
      margin-right: 0;
    }

    svg {
      width: 14px;
      height: 11px;
      margin-right: 16px;
    }

    ${responsive.md`
      font-size: ${rem(16)};
      line-height: ${rem(22)};
      margin-right: 56px;
    `};
  }
`;

const DiscountBannerMobile = styled(DiscountBanner)`
  position: absolute;
  bottom: 12px;
  left: -11px;

  ${responsive.sm`
    display: none;
  `};
`;

const BundleRibbonStyle = css`
  display: inline-flex;
  transform: translateY(-5px);
  margin-left: 12px;

  ${responsive.md`
    transform: translateY(-10px);
  `};
`;

export default class BundleLandingHero extends React.Component {
  handleSegmentEvent(title) {
    metrics.track("CTA Clicked", {
      location: "hero",
      title,
      nonInteraction: false,
    });
  }

  heroContent() {
    const {
      discount,
      subheader,
      title,
      hasActiveSubscription,
      hasBundlePromoEligibleSubscription,
    } = this.props;

    return (
      <>
        <HeroContent>
          <h1>
            {title}{" "}
            {hasBundlePromoEligibleSubscription && (
              <>
                <BundleRibbon
                  short={true}
                  white={true}
                  className="d-none d-sm-inline-flex d-md-none"
                  contentStyle={BundleRibbonStyle}
                />
                <BundleRibbon
                  white={true}
                  className="d-none d-md-inline-flex"
                  contentStyle={BundleRibbonStyle}
                />
              </>
            )}
          </h1>
          <p>{subheader}</p>
          {hasActiveSubscription ? (
            <ButtonWrapper className="activeSubscription">
              <BundleButton
                to="/add-mens"
                title={intl.t(
                  "bundle-landing.benefits.button-mens",
                  "Add Men’s",
                )}
                className="alt-hover"
                onClick={this.handleSegmentEvent.bind(this, "Add Men’s")}
              >
                {intl.t("bundle-landing.benefits.button-mens", "Add Men’s")}
              </BundleButton>
              <BundleButton
                to="/add-womens"
                title={intl.t(
                  "bundle-landing.benefits.button-womens",
                  "Add Women’s",
                )}
                className="alt-hover"
                onClick={this.handleSegmentEvent.bind(this, "Add Women’s")}
              >
                {intl.t("bundle-landing.benefits.button-womens", "Add Women’s")}
              </BundleButton>
            </ButtonWrapper>
          ) : (
            <ButtonWrapper>
              <BundleButton
                to="/build-a-bundle"
                title={intl.t(
                  "bundle-landing.benefits.button-build",
                  "Build Your Bundle",
                )}
                className="alt-hover"
                onClick={this.handleSegmentEvent.bind(this, "build a bundle")}
              >
                {intl.t(
                  "bundle-landing.benefits.button-build",
                  "Build Your Bundle",
                )}{" "}
                <Icons.ArrowRoundedRight />
              </BundleButton>
              {discount && (
                <DiscountBanner>
                  <BundleRibbon white={true} />
                </DiscountBanner>
              )}
            </ButtonWrapper>
          )}
        </HeroContent>
        {discount && (
          <DiscountBannerMobile>
            {" "}
            <BundleRibbon white={true} />
          </DiscountBannerMobile>
        )}
      </>
    );
  }

  render() {
    const {
      title,
      subheader,
      heroImageDesktop,
      heroImageMobile,
      hasActiveSubscription,
    } = this.props;

    return (
      <Wrapper>
        <PageHero
          imageDesktop={heroImageDesktop}
          imageMobile={heroImageMobile}
          backgroundColor={Color.ritualYellow}
          className="short"
          columnStyling="col-12 col-sm-5 offset-sm-7"
        >
          {this.heroContent()}
        </PageHero>

        <BarrierBustersBanner>
          <p>
            <Icons.CheckRounded />
            Free shipping—always
          </p>
          <p>
            <Icons.CheckRounded />
            Easy cancellation—anytime
          </p>
          <p>
            <Icons.CheckRounded />
            Don’t love it? It’s on us.
          </p>
        </BarrierBustersBanner>

        <Container className="mt-4 d-block d-sm-none">
          <Row>
            <MobileColumn className="col-12">
              <h1>{title}</h1>
              <p>{subheader}</p>
              {hasActiveSubscription ? (
                <ButtonWrapper>
                  <BundleButton
                    to="/add-mens"
                    title="Add Men’s"
                    className="fullwidth"
                    onClick={this.handleSegmentEvent.bind(this, "Add Men’s")}
                  >
                    Add Men’s
                  </BundleButton>
                  <BundleButton
                    to="/add-womens"
                    title="Add Women’s"
                    className="fullwidth"
                    onClick={this.handleSegmentEvent.bind(this, "Add Women’s")}
                  >
                    Add Women’s
                  </BundleButton>
                </ButtonWrapper>
              ) : (
                <BundleButton
                  to="/build-a-bundle"
                  title="Build Your Bundle"
                  className="fullwidth"
                  onClick={this.handleSegmentEvent.bind(this, "build a bundle")}
                >
                  Build Your Bundle <Icons.ArrowRoundedRight />
                </BundleButton>
              )}
              <BarrierBustersList>
                <li>
                  <Icons.CheckRounded />
                  Free shipping
                </li>
                <li>
                  <Icons.CheckRounded />
                  Easy cancellation
                </li>
              </BarrierBustersList>
            </MobileColumn>
          </Row>
        </Container>
      </Wrapper>
    );
  }
}
