import React from "react";
import styled from "styled-components";

// Services
import intl from "../../services/intl";

// Utils
import { Font, responsive, Color, rem } from "../../utils/style";
import { Icons } from "../../utils/svg";
import metrics from "../../utils/metrics";

// Components
import Container from "../Container";
import Row from "../Row";
import FeaturedArea from "../home/FeaturedArea.New";
import RitualButton from "../global/RitualButton";
import Text from "../Text";

// Styled Elements
const Wrapper = styled.div`
  .flex-column {
    margin-top: 0 !important;
  }
`;

const BenefitsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;

const BenefitsList = styled.ul`
  margin: 0 0 32px !important;
  padding: 0 !important;
  list-style: none;

  ${responsive.sm`
    margin-bottom: 0 !important;
  `};

  ${responsive.md`
    margin-bottom: 40px !important;
  `};

  li {
    margin: 0 0 24px !important;
    padding: 0 !important;

    display: flex;

    ${responsive.md`
        margin-bottom: 32px !important;
    `};

    &:last-child {
      margin-bottom: 0 !important;
    }

    svg {
      flex: 0 0 32px;
      width: 32px !important;
      height: 32px !important;
      margin-right: 16px;

      ${responsive.md`
        width: 40px !important;
        height: 40px !important;
        flex: 0 0 40px;
      `}
    }

    h4 {
      ${Font.circular};
      font-size: ${rem(18)};
      line-height: ${rem(28)};
      font-weight: 500;
      margin: 0 0 8px;

      ${responsive.md`
        font-size: ${rem(22)};
        line-height: ${rem(32)};
        letter-spacing: -0.2px;
      `};
    }

    p {
      ${Font.circular};
      font-size: ${rem(14)};
      line-height: ${rem(20)};
      font-weight: 300;
      margin: 0;

      ${responsive.md`
        font-size: ${rem(16)};
        line-height: ${rem(22)};
      `};
    }
  }
`;

const BundleButton = styled(RitualButton)`
  width: 100% !important;

  ${responsive.sm`
    width: auto !important;
  `};

  ${responsive.md`
    width: auto !important;
  `};

  svg {
    width: 19px;
    height: 12px;
    margin-left: 16px;

    g {
      transition: stroke 0.2s;
      stroke: white;
    }
  }

  &:hover {
    opacity: 1 !important;

    svg {
      g {
        stroke: ${Color.ritualBlue};
      }
    }
  }
`;

const BenefitsTitle = styled.h3`
  text-align: left;
  font-size: ${rem(26)} !important;
  line-height: ${rem(36)} !important;
  font-weight: 500;
  letter-spacing: -0.3px !important;
  margin: 32px 0 24px !important;
  max-width: 230px;

  ${responsive.sm`
    text-align: center !important;
    margin-top: 0 !important;
    margin-bottom: 40px !important;
    max-width: none;
  `};

  ${responsive.md`
    font-size: ${rem(40)} !important;
    line-height: ${rem(50)} !important;
    letter-spacing: -1px !important;
    margin-bottom: 56px !important;
  `};
`;

const ButtonWrapper = styled.div`
  width: 100%;

  ${responsive.sm`
    width: unset;
  `};

  a {
    width: 100% !important;

    ${responsive.sm`
      width: 165px !important;
    `};

    &:first-child {
      margin-right: 24px;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;

export default class BundleBenefits extends React.Component {
  handleClick(title) {
    metrics.track("CTA Clicked", {
      location: "benefits",
      title,
      nonInteraction: false,
    });
  }

  render() {
    const { benefits, className, image, hasActiveSubscription } = this.props;

    if (!benefits) return null;

    return (
      <Wrapper className={className}>
        <Container className="d-none d-sm-block">
          <Row>
            <div className="col-12">
              <BenefitsTitle>{benefits.title}</BenefitsTitle>
            </div>
          </Row>
        </Container>

        <FeaturedArea
          indexId="0"
          align="right"
          image={image}
          background="#ffd600"
          markdown={undefined}
          className="pb-0 mb-7 mb-sm-0"
        >
          <BenefitsWrapper>
            <BenefitsTitle className="d-sm-none">
              {benefits.title}
            </BenefitsTitle>
            <BenefitsList>
              {benefits.items &&
                benefits.items.map((item, i) => {
                  const Icon = Icons[item.icon];
                  return (
                    <li key={i}>
                      {Icon && <Icon />}
                      <div>
                        <h4>{item.title}</h4>
                        <p>{item.body}</p>
                      </div>
                    </li>
                  );
                })}
            </BenefitsList>
            {hasActiveSubscription ? (
              <ButtonWrapper className="mt-sm-6 mt-md-0">
                <BundleButton
                  onClick={this.handleClick.bind(this, "Add Men’s")}
                  to="/add-mens"
                  title={intl.t(
                    "bundle-landing.benefits.button-mens",
                    "Add Men’s",
                  )}
                  className="d-sm-none d-md-flex"
                >
                  <Text
                    id="bundle-landing.benefits.button-mens"
                    defaultMessage="Add Men’s"
                  />
                </BundleButton>
                <BundleButton
                  onClick={this.handleClick.bind(this, "Add Women’s")}
                  to="/add-womens"
                  title={intl.t(
                    "bundle-landing.benefits.button-womens",
                    "Add Women’s",
                  )}
                  className="d-sm-none d-md-flex"
                >
                  <Text
                    id="bundle-landing.benefits.button-womens"
                    defaultMessage="Add Women’s"
                  />
                </BundleButton>
              </ButtonWrapper>
            ) : (
              <BundleButton
                onClick={this.handleClick.bind(this, "build a bundle")}
                to="/build-a-bundle"
                title={intl.t(
                  "bundle-landing.benefits.button-build",
                  "Build Your Bundle",
                )}
                className="d-sm-none d-md-flex"
              >
                <Text
                  id="bundle-landing.benefits.button-build"
                  defaultMessage="Build Your Bundle"
                />
                <Icons.ArrowRoundedRight />
              </BundleButton>
            )}
          </BenefitsWrapper>
        </FeaturedArea>

        <Container className="d-none d-sm-block d-md-none mt-4">
          <Row>
            <div className="col-12 text-center">
              {hasActiveSubscription ? (
                <ButtonWrapper>
                  <BundleButton
                    onClick={this.handleClick.bind(this, "Add Men’s")}
                    to="/add-mens"
                    title={intl.t(
                      "bundle-landing.benefits.button-mens",
                      "Add Men’s",
                    )}
                  >
                    <Text
                      id="bundle-landing.benefits.button-mens"
                      defaultMessage="Add Men’s"
                    />
                  </BundleButton>
                  <BundleButton
                    onClick={this.handleClick.bind(this, "Add Women’s")}
                    to="/add-womens"
                    title={intl.t(
                      "bundle-landing.benefits.button-womens",
                      "Add Women’s",
                    )}
                  >
                    <Text
                      id="bundle-landing.benefits.button-womens"
                      defaultMessage="Add Women’s"
                    />
                  </BundleButton>
                </ButtonWrapper>
              ) : (
                <BundleButton
                  onClick={this.handleClick.bind(this, "build a bundle")}
                  to="/build-a-bundle"
                  title={intl.t(
                    "bundle-landing.benefits.button-build",
                    "Build Your Bundle",
                  )}
                >
                  <Text
                    id="bundle-landing.benefits.button-build"
                    defaultMessage="Build Your Bundle"
                  />{" "}
                  <Icons.ArrowRoundedRight />
                </BundleButton>
              )}
            </div>
          </Row>
        </Container>
      </Wrapper>
    );
  }
}
