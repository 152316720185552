import React from "react";
import styled from "styled-components";

// Utils
import { responsive } from "../../utils/style";

// Styled Elements
const Wrapper = styled.div`
  height: fit-content;
  max-height: fit-content;
  width: calc(100vw - 16px);

  margin-left: auto;
  margin-right: auto;
  padding-left: 8px;
  padding-right: 8px;

  ${responsive.sm`
    width: calc(100vw - 48px);
    padding-left: 0px;
    padding-right: 0px;
  `};

  ${responsive.lg`
    width: calc(100vw - 80px);
    max-width: 1920px;
    max-height: calc(1920px * 0.3888024883);
  `};
`;
export default class LargeContainer extends React.Component {
  render() {
    const { children, className = "" } = this.props;
    return <Wrapper className={className}>{children}</Wrapper>;
  }
}
