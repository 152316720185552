import React from "react";
import styled from "styled-components";

// Utils
import { Font, media, Color, rem, responsive } from "../../utils/style";
import NameMap from "../../utils/nameMap";
import { Icons } from "../../utils/svg";

// Styled Elements
const ToggleWrapper = styled.div`
  position: relative;

  border-bottom: ${p => (p.open ? "none" : `2px solid ${Color.ritualBlue}`)};

  ${media.mobile`
    cursor: pointer;
  `};

  ${responsive.sm`
    border-bottom: none;
  `};
`;

const ProductName = styled.div`
  position: relative;

  h2 {
    ${Font.circular};
    font-size: ${rem(20)};
    line-height: 30px;
    color: ${Color.ritualBlue};
    margin: 16px 0 8px;

    ${responsive.sm`
      margin-top: 0;
    `};

    em {
      ${Font.dutch};
      font-style: italic;
    }
  }

  svg {
    position: absolute;
    top: calc(50% - 9px);
    right: 0;
    width: 18px;
    height: 18px;

    transform: ${p => (p.open ? "rotate(180deg)" : "rotate(0deg)")};

    ${responsive.sm`
      display: none;
    `};
  }
`;

const Label = styled.p`
  color: rgba(20, 43, 111, 0.56);
  ${Font.circular}
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.8px;
  line-height: 20px;
  text-transform: uppercase;
  margin-bottom: 16px;
`;

const IngredientRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const IngredientText = styled.p`
  ${Font.circular};
  color: ${Color.ritualBlue};
  font-size: ${rem(16)};
  line-height: ${rem(26)};
  font-weight: 500;
  margin: 0;
`;

const IngredientDosage = styled.p`
  ${Font.circular};
  color: ${Color.ritualBlue};
  font-size: ${rem(14)};
  line-height: ${rem(20)};
  font-weight: 300;
  margin: 0;
`;

const ListWrapper = styled.div`
  display: block;

  display: ${p => (p.open ? `block;` : `none`)};
  border-bottom: ${p => (p.open ? `2px solid ${Color.ritualBlue}` : "none")};
  padding-bottom: 16px;

  ${responsive.sm`
    display: block !important;
    border-bottom: none;
  `};
`;

export default class ModalIngredientList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  toggleList() {
    this.setState(prevState => ({
      open: !prevState.open,
    }));
  }

  render() {
    const { open } = this.state;
    const { product } = this.props;

    if (!product) return null;

    const ingredients = product?.supplementFacts?.simpleIngredients;
    if (!ingredients) return null;

    const productName = NameMap(product.name);

    return (
      <>
        <ToggleWrapper open={open} onClick={this.toggleList.bind(this)}>
          <ProductName open={open}>
            <h2
              dangerouslySetInnerHTML={{
                __html: productName.html,
              }}
            ></h2>
            <Icons.CaretDownRounded />
          </ProductName>
          <Label>{ingredients.length} Ingredients</Label>
        </ToggleWrapper>
        <ListWrapper open={open}>
          {ingredients &&
            ingredients.map((ingredient, i) => {
              return (
                <IngredientRow key={i}>
                  <IngredientText>{ingredient.name}</IngredientText>
                  <IngredientDosage>{`${ingredient.dosage}`}</IngredientDosage>
                </IngredientRow>
              );
            })}
        </ListWrapper>
      </>
    );
  }
}
